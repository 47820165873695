import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/home';
import Page1 from './pages/page1';
import Page2 from './pages/page2';

function App() {
  return (
    <>
      <BrowserRouter>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/page1">Page 1</Link></li>
          <li><Link to="/page2">Page 2</Link></li>
        </ul>

        <Switch>
          <Route path="/page1"><Page1 /></Route>
          <Route path="/page2"><Page2 /></Route>
          <Route path="/"><Home /></Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
