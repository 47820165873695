
import { useState, useEffect } from 'react';
import { auth } from '../database/firebase';

function Header() {

    const [user, setUser] = useState(null);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUser(user);
        });
    }, [user]);

    return (
        <>
            <h1>Header</h1>
            { user ? (<a href='/logout'>Logout</a>) : (<a href='/login'>Login</a>)}
        </>
    )
}

export default Header;