import Header from './header';

function Page1() {
    return (
        <>
            <Header />
        </>
    );
}

export default Page1;