
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC-92I5EAzqoiZcXEPuMjmtqTkiMoA2lTs",
    authDomain: "spicycat-lkmeyw.firebaseapp.com",
    databaseURL: "https://spicycat-lkmeyw.firebaseio.com",
    projectId: "spicycat-lkmeyw",
    storageBucket: "spicycat-lkmeyw.appspot.com",
    messagingSenderId: "287432506052",
    appId: "1:287432506052:web:2e51305ef995edad18a791"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
