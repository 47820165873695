import Header from './header';

function Page2() {
    return (
        <>
            <Header />
        </>
    );
}

export default Page2;